import React from 'react';

const Team = () => (
<section id="team">
    <div>
      <div>
        <h2 className="team__titlemain">The Team</h2>
      </div>
    </div>
    <div className="team">
      <div className="team__informationkrai">
        <picture>
          <source srcSet="./images/krai-pixel-panda.png 2x, ./images/krai-pixel-panda.png 1x" />
          <img
            src="./images/krai-pixel-panda.png"
            alt="Krai"
            className="team__picture-image"
          />
        </picture>
        <h2 className="team__title">
          Krai
        </h2>
        <p className="team__text">
          Artist
        </p>
        <p className="team__text">
          A contemporary Punjabi-Canadian artist versed in fine arts, design, and architecture. Recent projects include murals, NFTs, and street furniture design.
        </p>
      </div>
      <div className="team__informationkaran">
        <picture>
          <source srcSet="./images/karan-pixel-panda.png 2x, ./images/karan-pixel-panda.png 1x" />
          <img
            src="./images/karan-pixel-panda.png"
            alt="Karan"
            className="team__picture-image"
          />
        </picture>
        <h2 className="team__title">
          Karan
        </h2>
        <p className="team__text">
         Project Director.
        </p>
        <p className="team__text">
          Crypto addict since 2017, with a background in entrepreneurship and a Bachelor’s in Economics. 
        </p>
      </div>
    </div>
  </section>
);

export default Team;
