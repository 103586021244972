import React from 'react';
import PropTypes from 'prop-types';

const HeroBackground = ({ className }) => (
  <div className={className}>
  </div>
);

HeroBackground.propTypes = {
  className: PropTypes.string,
};

HeroBackground.defaultProps = {
  className: '',
};

export default HeroBackground;
