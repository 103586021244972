import shortid from 'shortid';
import normies from '../../assets/images/rarity-common.svg';
import zombies from '../../assets/images/rarity-uncommon.svg';
import skeletons from '../../assets/images/rarity-rare.svg';
import nearkats from '../../assets/images/rarity-very-rare.svg';

const rarityData = {
  rates: [
    {
      id: shortid.generate(),
      svgIcon: normies,
      text: 'Background',
    },
    {
      id: shortid.generate(),
      svgIcon: zombies,
      text: 'Body',
    },
    {
      id: shortid.generate(),
      svgIcon: skeletons,
      text: 'Mouth',
    },
    {
      id: shortid.generate(),
      svgIcon: nearkats,
      text: 'Eyes',
    },
    {
      id: shortid.generate(),
      svgIcon: skeletons,
      text: 'Accessory',
    },
    {
      id: shortid.generate(),
      svgIcon: zombies,
      text: 'Hat',
    },
    {
      id: shortid.generate(),
      svgIcon: normies,
      text: 'Clothing',
    },
  ],
  misfits: [
    {
      id: shortid.generate(),
      svgRarityIcon: normies,
      alt: 'normies icon',
      src: './images/pixel-pandas-dna-04.png',
      srcSet:
        './images/pixel-pandas-dna-04.png 2x, ./images/pixel-pandas-dna-04.png 1x',
    },
    {
      id: shortid.generate(),
      svgRarityIcon: normies,
      alt: 'normies icon',
      src: './images/pixel-pandas-dna-03.png',
      srcSet:
        './images/pixel-pandas-dna-03.png 2x, ./images/pixel-pandas-dna-03.png 1x',
    },
    {
      id: shortid.generate(),
      svgRarityIcon: normies,
      alt: 'normies icon',
      src: './images/pixel-pandas-dna-02.png',
      srcSet:
        './images/pixel-pandas-dna-02.png 2x, ./images/pixel-pandas-dna-02.png 1x',
    },
    {
      id: shortid.generate(),
      svgRarityIcon: normies,
      alt: 'normies icon',
      src: './images/pixel-pandas-dna-04.png',
      srcSet:
        './images/pixel-pandas-dna-01.png 2x, ./images/pixel-pandas-dna-01.png 1x',
    },
  ],
};

export default rarityData;
