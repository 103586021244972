import React from 'react';

const Roadmap = () => (
<section id="roadmap">
    <div className="roadmap">
      <div className="roadmap">
        <h2 className="roadmap__titlemain">Roadmap</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">Future Panda Street plans.</p>
      </div>
    </div>
    <div className="roadmap">
      <div className="roadmap">
        <h2 className="roadmap__title">NFT Release.</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">Pixel Pandas Incoming on January 17th, 2022.</p>
      </div>
    </div>
    <div className="roadmap">
      <div className="roadmap">
        <h2 className="roadmap__title">Website Relaunch.</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">A new integrated website is currently under construction and awaiting relaunch in the coming month.</p>
      </div>
    </div>
    <div className="roadmap">
      <div className="roadmap"> 
        <h2 className="roadmap__title">Streetwear.</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">The Artist has a vision to develop Panda Street into one of the world’s top streetwear brands. Once the NFT sales are concluded, this will be the main focus of the project.

Streetwear merch has currently been in development and will be expecting release in February 2022, so you can finally rock your Pandas. </p>
      </div>
    </div>
    <div className="roadmap">
      <div className="roadmap"> 
        <h2 className="roadmap__title">Charity.</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">After donating over $31,000 to Charity in 2021, Panda Street aims to match that in 2022.</p>
      </div>
    </div>
    <div className="roadmap">
      <div className="roadmap"> 
        <h2 className="roadmap__title">Benefits to Holders.</h2>
      </div>
      <div className="roadmap">
        <p className="roadmap__text">Panda holders have been airdropped exclusive projects and coins, and there will be more coming down the line in the future.</p>
      </div>
    </div>
  </section>
);

export default Roadmap;
