import React from 'react';
import { ReactSVG } from 'react-svg';
import RateList from '../RateList';
import rarityData from './rarityData';
import gradient from '../../assets/images/rarity-background-gradient.svg';
import RarityList from './RarityList';

const Rarity = () => (
  <section id="rarity" className="rarity">
    <div className="rarity__header">
      <h1 className="rarity__title">Traits of Pixel Pandas (Rarity)</h1>
      <RateList rates={rarityData.rates} />
      <ReactSVG className="rarity__header-background" src={gradient} />
    </div>
    <div className="rarity__content-wrapper">
      <div className="rarity__content">
        <div className="rarity__information">
          <p className="rarity__text">
            All Pandas are created to reflect streetwear personalities ones rarest than others!
          </p>
          <p className="rarity__text">
            Each one of come from a different cultural time tribe
          </p>
        </div>
        <RarityList list={rarityData.misfits} />
      </div>
    </div>
  </section>
);

export default Rarity;
