import React from 'react';
import CalendarBtn from './CalendarBtn';
import HeroBackground from './HeroBackground';

const Hero = () => (
  <div className="hero">
    <div className="hero__container">
      <HeroBackground className="hero__background" />

      <div className="hero__information">
        <h1 id="home" className="hero__title">
          Pixel Pandas <br />
        </h1>
        <h1 className="hero__title">
          <p className="hero__title_purpure">
            Your favorite Pandas
          </p>
          <p className="hero__title_purpure">
            Just got a whole lot cuter
          </p>
        </h1>
        <p className="hero__text">
        On the first full moon of 2022, the King Panda was asleep in his Penthouse when the most peculiar thing happened. The clock struck midnight, and there was a deafening BOOM that rattled him awake. Suddenly, there was a blinding bright light coming through the windows. King Panda stepped out onto the balcony to see thousands of tiny pixels floating down from a huge vessel towering over Panda Street. Some say it was a glitch in the matrix. Some say it was just a dream. The Pixel Pandas have arrived.
        </p>
        <p className="hero__text">Pixel Pandas Drop on 17th</p>
        <CalendarBtn className="hero__calendar-btn" />
        <picture>
          <source srcSet="./images/pixel-pandas-gif.gif 2x, ./images/pixel-pandas-gif.gif 1x" />
          <img
            className="hero__imagegif"
            src="./images/pixel-pandas-gif.gif"
            alt="pixel panda gif"
          />
        </picture>
      </div>
    </div>
  </div>
);

export default Hero;
