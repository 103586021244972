import React from 'react';

const FAQ = () => (
  <div id="faq" className="faq__wrapper">
    <section className="faq">
      <div className="faq__container">
        <h2 className="faq__title">Frequently Asked Questions</h2>
        <ul className="faq__list">
          <li className="faq__item">
            <h3 className="faq__item-title">What is Pixel Panda?</h3>
            <p className="faq__item-text">
              Pixel Pandas are inspired by the Original Panda Street collection,
              but in a playful new style to represent your digital identity. The
              Pixels are launching on NEAR Protocol.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">When Mint?</h3>
            <p className="faq__item-text">January 17th, 2022.</p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">Mint Pirce?</h3>
            <p className="faq__item-text">TBD.</p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">Proceeds to Charity?</h3>
            <p className="faq__item-text">
              10% of the funds generated from mint will be donated to a sel
              ected charity.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">Collection Size?</h3>
            <p className="faq__item-text">
              There will be a limited edition of 2,222 Pixel Pandas.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              Will you be donating to the DAO?
            </h3>
            <p className="faq__item-text">
              5% of proceeds will be donated towards our community 
              <a href="https://twitter.com/PS_PanDAO"> <u>DAO</u></a> Fund.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              Why Are the Pandas going Multi-Chain?
            </h3>
            <p className="faq__item-text">
              Launching on NEAR is a strategic move with long term goals to create a diverse 
              and stronger community.

              It also gives us more flexibility: the options are limitless with multi-chain 
              opportunities to build and grow on.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              Why NEAR?
            </h3>
            <p className="faq__item-text">
              <a href="https://near.org/"><u>Near Protocol</u> </a> 
              is a climate-neutral, high-speed, and low transaction fee Layer-1 Blockchain 
              platform. Taking advantage of being early on a blockchain with high speeds, low fees, and 
              progressive UX, definitely has its benefits. There is a lot of potential for future growth.
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">What Happens to the Original Collection?</h3>
            <p className="faq__item-text">
              Creating a new collection does not, in any way, replace the original collection. 
              Our focus is to build community on both chains and create even more unique holders.
            </p>
          </li>
        </ul>
      </div>
    </section>
  </div>
);

export default FAQ;
